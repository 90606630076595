import React from 'react';
import { Route, Routes } from 'react-router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Navbar from './components/Navbar';
import Upload from './components/Upload';
import Edit from './components/Edit';
import Login from './components/Login';
import Missing from './components/Missing';
import Gallery from './components/Gallery';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';

function App() {
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Routes>
        {/* Open routes */}
        <Route path="/" element={<Gallery />} />
        <Route path="home" element={<Gallery />} />
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="missing" element={<Missing />} />
        <Route path="*" element={<Missing />} />

        {/* Protected routes */}
        <Route element={<RequireAuth allowedRoles={['write']} />}>
          <Route path="upload" element={<Upload />} />
          <Route path="edit" element={<Edit />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={['read']} />}>
          {/* READ role - empty for now */}
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
