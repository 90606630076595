const telescope = [
  { name: 'William Optics Z73' }, { name: 'Bresser 80/400' }, { name: 'Canon EF 70-200mm L' },
];

const mount = [
  { name: 'EQ6-R Pro' }, { name: 'HEQ5 Pro' },
];

const filter = [
  { name: 'None' }, { name: 'ZWO UV/IR Cut' }, { name: 'Astronomik CLS' }, { name: 'Optolong L-eXtreme' },
];

const camera = [
  { name: 'Canon 600D (modified)' }, { name: 'ASI533MC Pro' },
];

const guideCamera = [
  { name: 'ASI120MM Mini' },
];

export {
  filter, camera, telescope, mount, guideCamera,
};
