import { Box } from '@mui/material';
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import image from '../img/bh.jpg';

function Missing() {
  return (

    <Box sx={{ width: '100%', height: 'auto' }}>
      <ImageList sx={{ mt: 1 }} variant="masonry" cols={1} gap={0}>
        <ImageListItem key="one">
          <img
            src={image}
            alt="Missing"
            loading="lazy"
          />
        </ImageListItem>
      </ImageList>
    </Box>
  );
}

export default Missing;
