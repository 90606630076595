/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ImageListItemBar, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Zoom from '@mui/material/Zoom';
import Backdrop from '@mui/material/Backdrop';
import axios from '../api/axios';

const { S3_URL, BUCKET_NAME } = require('../values/config');

export default function Gallery() {
  const [images, setImages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState(false);
  const [selected, setSelected] = useState('');
  const [meta, setMeta] = useState({});
  const [dim, setDim] = useState(false);

  const sxDim = {
    opacity: 0.1,
  };
  const sxUndim = {
    opacity: 1,
  };

  const handleClose = () => {
    setInfo(false);
    setOpen(false);
    setSelected('');
    setDim(false);
  };

  const handleOpen = (item) => {
    setOpen(true);
    setSelected(item.key.split('/')[1]);
    setDim(true);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const showImage = () => (
    <Backdrop
      sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      onClick={handleClose}
      open={open && !info}
    >
      <Box sx={{
        m: 0,
        // width: '100%',
        // height: 'auto',
        // width: 'vw',
        // height: 'vh',
      }}
      >
        <ImageListItem key={selected}>
          <img
            src={`${S3_URL}${BUCKET_NAME}/${selected}`}
            alt={selected}
            loading="lazy"
          />
        </ImageListItem>
      </Box>
    </Backdrop>
  );

  const showInfo = () => (
    <Backdrop
      open={info}
      onClick={handleClose}
    >
      <Box>
        <Typography
          sx={{ fontWeight: '700', fontSize: 'larger' }}
        >
          Object:
          {' '}
          {meta.target}
        </Typography>
        <br />

        {Object.keys(meta).map((key) => (
          <Typography
            sx={{ color: 'rgba(255, 255, 255, 0.75)' }}
            key={key}
          >
            {key === 'integration' ? (
              `${capitalizeFirstLetter(key)}: ${meta[key]} minutes`
            ) : (
              `${capitalizeFirstLetter(key)}: ${meta[key]}`
            )}
          </Typography>
        ))}
      </Box>
    </Backdrop>
  );

  useEffect(() => {
    const getImages = async () => {
      try {
        const result = await axios.get('/astro/gallery');
        const thumbs = result.data.result.filter((item) => item.key.includes('tmb/'));
        setImages(thumbs);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        setImages([]);
      }
    };
    getImages();
  }, []);

  return (
    <Box sx={{
      mx: 1,
      my: -1,
      width: 'vw',
      height: 'vh',
    }}
    >
      <Typography variant="caption" display="block" color="#999" align="center" mt={1} mb={-2}>Long download times due to intentionally large images for quality.</Typography>
      <ImageList variant="masonry" cols={2} gap={6}>
        {images.map((item) => (
          <ImageListItem
            sx={{
              ...(!dim && sxUndim),
              ...(dim && sxDim),
            }}
            key={item.key}
            onClick={() => handleOpen(item)}
          >
            <img
              srcSet={`${S3_URL}${BUCKET_NAME}/${item.key}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${S3_URL}${BUCKET_NAME}/${item.key}?w=248&fit=crop&auto=format`}
              alt={item.key}
              loading="lazy"
            />

            <ImageListItemBar
              sx={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
              key={item.key}
              title={item.meta.target}
              // subtitle={item.meta.date}
              actionIcon={(
                <IconButton
                  onClick={() => {
                    setInfo(true);
                    setMeta(item.meta);
                  }}
                  sx={{ color: 'rgba(255, 255, 255, 0.5)', '&:hover': { color: 'rgba(255, 255, 255, 0.9)' } }}
                  aria-label={`info about ${item.meta.target}`}
                >
                  <Tooltip title="Image Info" TransitionComponent={Zoom} placement="top" arrow>
                    <InfoOutlinedIcon />
                  </Tooltip>
                </IconButton>
            )}
            />
          </ImageListItem>
        ))}
      </ImageList>

      {info ? showInfo() : showImage()}

    </Box>
  );
}
