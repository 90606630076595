/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControl, Stack, Box, MenuItem, Button, Typography, Skeleton,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  filter, camera, telescope, mount, guideCamera,
} from '../values/equipment';
import catalog from '../values/catalog';
import axios from '../api/axios';
// import testImg from '../img/bh.jpg';

function Upload() {
  const [success, setSuccess] = useState(false);
  const [image, setImage] = useState('');
  const {
    handleSubmit, register, control,
  } = useForm();

  useEffect(() => console.log(image), [image]);

  const onSubmit = async (data) => {
    try {
      const metaData = { ...data };
      delete metaData.file;

      const response = await axios.post('/astro/gallery', {
        metaData,
        image: data.file[0],
      }, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true,
      });
      // eslint-disable-next-line no-console
      console.log(response);
      setSuccess(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error({ error: err.message });
    }
  };

  return (

    <Box sx={{ width: '100%', height: 'auto' }}>
      <Typography variant="h5" sx={{ mt: 1, mx: 4 }}>
        New image upload
      </Typography>
      <Button sx={{ mb: 0, mx: 4 }} type="reset" color="error" size="small" onClick={() => window.location.reload(false)} startIcon={<ClearIcon />}>RESET FORM</Button>

      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <FormControl sx={{ width: '100%', height: 'auto' }}>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            useFlexGap
            flexWrap="wrap"
          >
            <Stack sx={{ width: '45%' }}>
              <Typography variant="h6" sx={{ m: 1 }}>Target info</Typography>

              <TextField
                sx={{ m: 1 }}
                label="Target"
                {...register('target')}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      sx={{ m: 1 }}
                      className="input"
                      label="Date"
                      format="YYYY-MM-DD"
                      placeholder="kjhkh"
                  // placeholderText="Select date"
                      selected={(field.value)}
                      onChange={(e) => field.onChange(e.format('YYYY-MM-DD'))}
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                sx={{ m: 1 }}
            // id="outlined-select-catalog"
                select
                label="Catalog"
                defaultValue=""
                {...register('catalog')}
              >
                {catalog.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ m: 1 }}
                multiline
            // rows={4}
                label="Frames"
                {...register('frames')}
              />
              <TextField
                sx={{ m: 1 }}
                label="Integration (minutes)"
                {...register('integration')}
              />

              {image
                ? (
                  <Button sx={{ m: 1 }} component="label" variant="outlined" color="success" endIcon={<DoneIcon />}>
                    <Typography>
                      {image.type.toUpperCase()}
                    </Typography>
                  </Button>
                ) : (
                  <Button sx={{ m: 1 }} component="label" variant="outlined" color="secondary" endIcon={<ClearIcon />}>
                    <Typography>
                      No image selected
                    </Typography>
                  </Button>
                )}

              <Button
                sx={{ m: 1 }}
                variant="contained"
                component="label"
                name="image"
              >
                Choose File
                <input
                  type="file"
                  hidden
                  {...register(
                    'file',
                    {
                      onChange: (e) => setImage(e.target.files[0]),
                    },
                  )}
                />
              </Button>
            </Stack>

            <Stack sx={{ width: '45%' }}>
              <Typography variant="h6" sx={{ m: 1 }}>Equipment</Typography>

              <TextField
                sx={{ m: 1 }}
                id="outlined-select-telescope"
                select
                label="Telescope"
                defaultValue=""
                {...register('telescope')}
              >
                {telescope.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ m: 1 }}
                id="outlined-select-mount"
                select
                label="Mount"
                defaultValue=""
                {...register('mount')}
              >
                {mount.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ m: 1 }}
                id="outlined-select-camera"
                select
                label="Camera"
                defaultValue=""
                {...register('camera')}
              >
                {camera.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ m: 1 }}
                id="outlined-select-filer"
                select
                label="Filter"
                defaultValue=""
                {...register('filter')}
              >
                {filter.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ m: 1 }}
                id="outlined-select-gCamera"
                select
                label="Guide Camera"
                defaultValue="ASI120MM"
                {...register('guideCamera')}
              >
                {guideCamera.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <Skeleton sx={{ m: 1 }} variant="rounded" height={36} animation={false} />
              <Button sx={{ m: 1 }} type="submit" color="success" variant="contained" startIcon={<CloudUploadIcon />}>UPLOAD FILE</Button>
            </Stack>
            <Box sx={{ m: 2 }}>
              {success
                ? <Button color="success" variant="contained" endIcon={<DoneIcon />}>Uploaded Successfully</Button>
                : <Button disabled variant="contained" endIcon={<ClearIcon />}>Uploaded Successfully</Button>}
            </Box>
            <Box sx={{ m: 2 }}>
              {image
                && (
                <img
                  src={image}
                  alt="img"
                  loading="lazy"
                />
                )}
            </Box>
          </Stack>
        </FormControl>
      </form>

    </Box>
  );
}

export default Upload;
