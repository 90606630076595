import React, {
  useRef, useState, useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Typography,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import TextField from '@mui/material/TextField';
import VerifiedIcon from '@mui/icons-material/Verified';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';

const LOGIN_URL = '/auth/login';

function Login() {
  const userRef = useRef();
  const { auth, setAuth } = useAuth();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  // Set focus on username input field
  useEffect(() => {
    userRef.current.focus();
  }, []);

  // clear error message if username or password changes
  // useEffect(() => {
  //   setErrMsg('');
  // }, [user, password]);

  const handleSubmitt = async (e) => {
    e.preventDefault();
    // make api call here
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      const { token, roles, avatar } = response.data;
      setAuth({
        user, token, roles, avatar,
      });
      setUser('');
      setPassword('');
      setErrMsg(false);
      navigate('/upload');
    } catch (err) {
      setErrMsg('Login failed');
    }
  };
  return (
    <Box sx={{ m: 4 }}>
      {auth?.user
        ? (
          <Box sx={{ m: 1 }}>
            <Typography>
              Logged in as:
            </Typography>
            <Button sx={{ my: 2 }} variant="contained" color="primary" disableElevation ref={userRef} endIcon={<VerifiedIcon />}>
              { auth?.user}
            </Button>
          </Box>
        )
        : (
          <Box sx={{ m: 1 }}>
            <Typography sx={{ m: 1 }} variant="h5">Supply Login Credentials</Typography>
            <form onSubmit={handleSubmitt}>

              {/* <label htmlFor="username">Username</label> */}
              <TextField
                sx={{ m: 1 }}
                label="username"
                type="text"
                id="usrname"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
              <br />

              {/* <label htmlFor="password">Password</label> */}
              <TextField
                sx={{ m: 1 }}
                label="password"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
              <br />

              <Button
                sx={{ m: 1 }}
                type="submit"
                variant="contained"
                endIcon={<LoginIcon />}
              >
                Log in
              </Button>
            </form>
            {errMsg && <Button sx={{ m: 1 }} color="error">{errMsg}</Button>}
          </Box>
        )}
    </Box>
  );
}

export default Login;
